'use client';

import { createToggleStore, useNextHydrated } from '@/lib/utils/createToggleStore';

import TestGrid from '@/components/_DevTestGrid/TestGrid';
import { useDraftMode } from '@/components/_PreviewToolbar/useDraftMode';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Backdrop,
  createTheme,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Slide,
  ThemeProvider,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

type Action = {
  icon: React.ReactNode;
  name: string;
  show: () => boolean;
  onClick?: () => void;
};

export const useDevMode = createToggleStore(false, 'devMode');
export const useDevGrid = createToggleStore(false, 'devGrid');

/**
 * This component provides a toolbar for development purposes.
 * It is only visible in development mode.
 * It provides the following features:
 * - Toggle the dev mode
 * - Toggle the dev grid
 * - Exit preview mode
 *
 * @remarks This component has its own theme to avoid conflicts with the main theme.
 */
const DevToolbar = () => {
  const isHydrated = useNextHydrated();

  const { enabled: draftModeEnabled, exit: exitDraftMode } = useDraftMode();
  const { value: devModeEnabled, toggle: toggleDevMode } = useDevMode();
  const { value: devGridEnabled, toggle: toggleDevGrid } = useDevGrid();

  const actions: Action[] = React.useMemo(
    () => [
      {
        icon: <ViewColumnIcon />,
        name: devGridEnabled ? 'Hide Columns' : 'Show Columns',
        show: () => true,
        onClick: toggleDevGrid,
      },
      {
        icon: <DeveloperModeIcon />,
        name: devModeEnabled ? 'Disable Dev Mode' : 'Enable Dev Mode',
        show: () => true,
        onClick: toggleDevMode,
      },
      {
        icon: <VisibilityOffIcon />,
        name: 'Exit Preview Mode',
        show: () => draftModeEnabled,
        onClick: exitDraftMode,
      },
    ],
    [devGridEnabled, devModeEnabled, draftModeEnabled, exitDraftMode, toggleDevGrid, toggleDevMode]
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return !isHydrated ? null : (
    <>
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}>
        <Backdrop
          open={open}
          onClick={handleClose}
          invisible
          sx={{
            zIndex: 9998,
          }}
        />
        <Box
          sx={{
            'transform': 'translateZ(0px)',
            'flexGrow': 1,
            'position': 'fixed',
            'bottom': 0,
            'right': 0,
            'textAlign': 'right',
            'zIndex': 9999,
            'padding': 1,
            'pointerEvents': 'none',
            '*': {
              pointerEvents: 'auto',
            },
          }}>
          <Slide in={open} direction="up">
            <Paper
              elevation={4}
              sx={{
                maxWidth: 360,
                bgcolor: 'background.paper',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginBottom: 1,
                borderRadius: 4,
              }}>
              <nav aria-label="dev options">
                <List dense>
                  {actions.map(
                    (action) =>
                      action.show() && (
                        <ListItem key={action.name} disablePadding>
                          <ListItemButton
                            sx={{ gap: 1 }}
                            onClick={() => {
                              handleClose();
                              action.onClick?.();
                            }}>
                            <ListItemText
                              primary={action.name}
                              color="#fff"
                              sx={{ textAlign: 'right' }}
                            />
                            <ListItemIcon sx={{ justifyContent: 'flex-end', minWidth: '40px' }}>
                              {action.icon}
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      )
                  )}
                </List>
              </nav>
            </Paper>
          </Slide>
          <Fab
            sx={{}}
            aria-label={'Dev toolbar'}
            color={'primary'}
            size="small"
            onMouseEnter={handleOpen}
            onClick={() => setOpen(!open)}>
            <SettingsIcon />
          </Fab>
        </Box>
      </ThemeProvider>
      {devGridEnabled && <TestGrid />}
    </>
  );
};

export default DevToolbar;
