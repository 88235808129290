'use client';

import useShortlistStore, { useShortlistCount, useShortlistLength } from '@/lib/store/shortlist';
import { Drawer } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import EventCard from '../EventCard';
import Icon from '../Icon';
import IconButton from '../IconButton';
import ShortlistCard from '../ShortlistCard';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './ShortlistDrawer.css';

const ShortlistDrawer = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [prevEvent, setPrevEvent] = useState<number>(0);
  const { events, latestEvent, notification, setNotification } = useShortlistStore();
  const entryCount = useShortlistStore(useShortlistCount);
  const hasEntries = useShortlistStore(useShortlistLength);

  const t = useTranslations();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (hasEntries) setOpen(false);
  }, [hasEntries]);

  useEffect(() => {
    if (notification) {
      setPrevEvent(entryCount);
      setNotification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryCount, prevEvent]);

  const TIMEOUT = 3000;

  useEffect(() => {
    if (notification) {
      const timeoutId = setTimeout(() => {
        setNotification(false);
      }, TIMEOUT);

      return () => clearTimeout(timeoutId);
    }
  }, [notification, setNotification]);

  return (
    <>
      <AnimatePresence>
        {hasEntries && (
          <Flex direction="row" alignItems="center" columnGap="2xs" className={styles.root}>
            <AnimatePresence>
              {notification && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Box
                    colorSet="white"
                    paper
                    bordered
                    rounded
                    cx={{ p: 'xs' }}
                    className={styles.notification}>
                    <Txt style={{ fontStyle: 'italic' }}>{latestEvent?.entryTitle}</Txt>
                    <Txt>{' - ' + t('actions.addedToShortlist')}</Txt>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
            <Btn
              className={styles.button}
              elevation="button"
              disableHover
              onClick={toggleDrawer(true)}>
              {entryCount && (
                <Txt as="span" variant="tiny">
                  {entryCount}
                </Txt>
              )}
              <Icon name="heart" cx={{ fontSize: 'h5' }} />
            </Btn>
          </Flex>
        )}
      </AnimatePresence>

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Flex className={styles.inner} cx={{ p: 'sm' }} rowGap="lg">
          <Flex direction="row" alignItems="center" justifyContent="between">
            <Txt color="primary" variant="h6" bodyFont weight="bold">
              {t('entryIndex.event') + ' ' + t('actions.shortlist')}
            </Txt>
            <IconButton color="primary" icon="close" onClick={toggleDrawer(false)} />
          </Flex>
          <ShortlistCard />
          {!!events?.length && (
            <Grid rowGutter>
              {events?.map((event, i) => {
                return (
                  <Grid.Col key={i}>
                    <EventCard {...event} />
                  </Grid.Col>
                );
              })}
            </Grid>
          )}
        </Flex>
      </Drawer>
    </>
  );
};

export default ShortlistDrawer;
