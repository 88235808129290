'use client';

import { useEffect } from 'react';
import { create as createStore } from 'zustand';
import { persist, StorageValue } from 'zustand/middleware';

export type ToggleState = {
  value: boolean;
  toggle: (value?: boolean) => void;
  on: () => void;
  off: () => void;
};

export type BooleanValueState = {
  value: boolean;
  setValue: (value: boolean) => void;
};

/** A store to record the state of Next Hydration */
export const useNextHydratedStore = createStore<BooleanValueState>((set) => ({
  value: false,
  setValue: (value: boolean) => set({ value }),
}));

/**
 * A hook to records and returns the state of Next JS hydration
 */
export const useNextHydrated = () => {
  const { value, setValue } = useNextHydratedStore();

  useEffect(() => {
    setValue(true);
    return () => setValue(false);
  }, [setValue]);
  return value;
};

export type PersistHydrationState<S> = S & {
  _hasHydrated: boolean;
  setHasHydrated: (value: boolean) => void;
};

/**
 * Create a store for a single boolean value, with optional persistent state view `localStorage`
 * @todo Pull hydration functionality out to use for a generic store
 */
export const createToggleStore = (defaultValue?: boolean, persistStoreKey?: string) => {
  const theStore = createStore<PersistHydrationState<ToggleState>>()(
    persist(
      (set) =>
        ({
          value: defaultValue ?? false,
          toggle: (on?: boolean) => {
            set((state) => ({ value: on ?? !state.value }));
          },
          off: () => set({ value: false }),
          on: () => set({ value: true }),
          _hasHydrated: false,
          setHasHydrated: (value: boolean) => {
            set({ _hasHydrated: value });
          },
        } as PersistHydrationState<ToggleState>),
      {
        /**
         * Initialise the store with placeholder settings, which store nothing
         * Real settings and hydrating occur with {@link hydrate}, below
         */
        name: '_',
        storage: {
          getItem: (name: string) => {
            const value = localStorage.getItem(name);
            return value ? JSON.parse(value) : null;
          },
          setItem: (name: string, value: StorageValue<ToggleState>) =>
            localStorage.setItem(name, JSON.stringify(value)),
          removeItem: (name: string) => localStorage.removeItem(name),
        },
        partialize: () => ({} as ToggleState),
        onRehydrateStorage: () => (state) => {
          state?.setHasHydrated(true);
        },
      }
    )
  );

  /**
   * To avoid a rehydration error, we wait for Next to hydrate,
   * then update the settings in our store to hydrate from `localStorage`
   * @see <https://github.com/pmndrs/zustand/issues/938>
   */
  const hydrate = () => {
    // update the settings
    theStore.persist.setOptions({
      // If no store key is set, we save nothing
      partialize: (state): ToggleState =>
        persistStoreKey
          ? state
          : ({
              value: defaultValue ?? false,
            } as ToggleState),
      name: persistStoreKey ?? '_',
    });

    // perform the hydration
    theStore.persist.rehydrate();
  };

  // Subscribe to the Next Hydration Store
  useNextHydratedStore.subscribe(({ value }) => {
    if (value) hydrate();
  });

  // Check if hydration has occurred already
  if (useNextHydratedStore.getState().value) hydrate();

  return theStore;
};
